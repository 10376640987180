//TODO: incorporate typography with this theme. Once stable, submit update to platform component (@abgov/angular-material-components)
@use '@angular/material' as mat;
@import '@angular/material/theming';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'colors.scss';
@import 'legacy-abgov/typography.scss';

$unit: 24px;
$half-unit: 0.5 * $unit;
$quarter-unit: 0.25 * $unit;
$double-unit: 2 * $unit;

body {
  margin: 0;
}

h2 {
  margin-bottom: $unit !important;
}

h3 {
  margin-top: $unit !important;
}

ul,
ol {
  padding-left: $unit;
}

li {
  margin: (0.5 * $unit) 0;
}

a {
  color: $goa-color-interactive;
}

em {
  font-size: inherit !important;
  line-height: inherit !important;
  color: inherit !important;
}

$ads-primary-palette: (
  50: #e0eef8,
  100: #b3d4ed,
  200: #80b8e2,
  300: #4d9bd6,
  400: #2685cd,
  500: var(--goa-color-interactive-default, #0070c4),
  600: #0068be,
  700: #005db6,
  800: #0053af,
  900: #0041a2,
  A100: #ccddff,
  A200: #99baff,
  A400: #6698ff,
  A700: #4d87ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: var(--goa-color-greyscale-white, #fff),
    500: var(--goa-color-greyscale-white, #fff),
    600: var(--goa-color-greyscale-white, #fff),
    700: var(--goa-color-greyscale-white, #fff),
    800: var(--goa-color-greyscale-white, #fff),
    900: var(--goa-color-greyscale-white, #fff),
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$ads-accent-palette: (
  // this is re-using the blue until we get more direction
  // only time this has been seen is checkbox in select menu
  50: #e0eef8,
  100: #b3d4ed,
  200: #80b8e2,
  300: #4d9bd6,
  400: #2685cd,
  500: var(--goa-color-interactive-default, #0070c4),
  600: #0068be,
  700: #005db6,
  800: #0053af,
  900: #0041a2,
  A100: #ccddff,
  A200: #99baff,
  A400: #6698ff,
  A700: #4d87ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: var(--goa-color-greyscale-white, #fff),
    500: var(--goa-color-greyscale-white, #fff),
    600: var(--goa-color-greyscale-white, #fff),
    700: var(--goa-color-greyscale-white, #fff),
    800: var(--goa-color-greyscale-white, #fff),
    900: var(--goa-color-greyscale-white, #fff),
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  )
);

$ads-warn-palette: mat.$red-palette;

$ads-primary-mat-palette: mat.define-palette($ads-primary-palette);
$ads-accent-mat-palette: mat.define-palette($ads-accent-palette);
$ads-warn-mat-palette: mat.define-palette($ads-warn-palette);

$ads-mat-typography-config: mat-typography-config(
  $font-family: 'acumin-pro-semi-condensed, sans-serif',
  $body-1: mat-typography-level($p-li-size, $p-li-line-height, $p-li-font-weight),
  $body-2: mat-typography-level($p-li-size, $p-li-line-height, 700),
  $headline: mat-typography-level($h1-size, $h1-line-height, $h1-font-weight),
  $title: mat-typography-level($h2-size, $h2-line-height, $h2-font-weight),
  $subheading-2: mat-typography-level($h3-size, $h3-line-height, $h3-font-weight),
  $subheading-1: mat-typography-level($h4-size, $h4-line-height, $h4-font-weight),
  $caption: mat-typography-level($caption-size, $caption-line-height, $caption-font-weight),
  $button: mat-typography-level($button-size, $button-line-height, $button-font-weight),
);

// Create the theme object (a Sass map containing all of the palettes).
$ads-mat-theme: mat.define-light-theme(
  (
    color: (
      primary: $ads-primary-mat-palette,
      accent: $ads-accent-mat-palette,
      warn: $ads-warn-mat-palette,
    ),
    density: 0,
    typography: $ads-mat-typography-config,
  )
);

.outline {
  outline: 3px solid var(--goa-color-interactive-focus, #feba35);
}

//As angular material already pre-defines styles, we only need to override targeted attributes.
//Using goa base classes would risk overriding angular material styles.
.ads-disabled {
  border: none;
  background-color: var(--goa-color-greyscale-100, #f1f1f1);
  color: var(--goa-color-greyscale-700, #666) !important;
  &:hover,
  &:focus,
  &:active {
    color: var(--goa-color-greyscale-700, #666);
  }
  &:focus,
  &:active {
    color: var(--goa-color-greyscale-700, #666);
    outline: none;
  }
}

.ads-primary {
  border: 2px solid;
  border-color: var(--goa-color-interactive-default, #0070c4);
  &:hover,
  &:focus,
  &:active {
    background-color: var(--goa-color-greyscale-black, #333);
    border-color: var(--goa-color-greyscale-black, #333);
  }
  &:focus,
  &:active {
    @extend .outline;
  }
  &[disabled] {
    @extend .ads-disabled;
  }
}

.ads-tertiary {
  color: var(--goa-color-interactive-default, #0070c4);
  border: 1px solid var(--goa-color-greyscale-200, #dcdcdc);
  &:hover,
  &:focus,
  &:active {
    color: var(--goa-color-greyscale-black, #333);
    border-color: var(--goa-color-greyscale-black, #333);
  }
  &:focus,
  &:active {
    @extend .outline;
  }
  &[disabled] {
    @extend .ads-disabled;
  }
}

.ads-secondary {
  border: 2px solid var(--goa-color-interactive-default, #0070c4);
  @extend .ads-tertiary;
}

@import './material-theming/tabs.scss';
@import './material-theming/sidenav.scss';
@import './material-theming/button-toggle.scss';
@import './material-theming/button.scss';
@import './material-theming/checkbox.scss';
@import './material-theming/radio.scss';
@import './material-theming/select.scss';
@import './material-theming/accordion.scss';
@import './material-theming/expansion-panel.scss';
@import './material-theming/snackbar.scss';
@import './material-theming/menu.scss';
@import './material-theming/dialog.scss';
@import './material-theming/list-option.scss';
@import './material-theming/progress-bar.scss';
@import './material-theming/chip.scss';
@import './material-theming/date-picker.scss';
