/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use 'ads-material-theme';

/* You can add global styles to this file, and also import other style files */
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes(ads-material-theme.$ads-mat-theme);
@include mat.core();

@import 'legacy-abgov/theme.scss';
@import 'legacy-abgov/input.scss';
@import 'legacy-abgov/notification.scss';

@import 'legacy-abgov/page-template.scss';
@include page-template;
@import 'colors.scss';

input[type='text'],
input[type='number'],
input[type='textarea'],
input[type='tel'],
select {
  @include goa-input-styles;
}

select {
  height: 54px;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}

.mat-radio-group {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.button-footer {
  display: flex;
  flex-direction: row;

  margin: 23px 0;

  .apply-button {
    margin-right: 30px;
  }
}

textarea {
  width: 100%;
  border-radius: 4px;
  &:focus {
    box-shadow: 0 0 0 3px #feba35;
    outline: none;
  }
  &.ng-invalid.ng-touched {
    border: 2px solid $common-error-color;
  }
}

// Hide number input arrows

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input {
  padding: 6px 12px !important;
}
.mat-mdc-select {
  /*padding: 6px 12px !important;*/
  padding-top: 6px;
  padding-bottom: 6px;
}
.error {
  .mat-radio-group {
    .mdc-radio__inner-circle {
      background-color: $common-error-color !important;
    }

    .mdc-radio__outer-circle {
      border-color: $common-error-color !important;
      border-width: 2px;
    }
  }

  .mdc-checkbox {
    .mdc-checkbox__background {
      border-color: $common-error-color !important;
      border-width: 2px;
    }
  }
}

.mat-stepper-horizontal {
  //make the stepper connector lines thicker, and shift them down because we made the icons larger
  .mat-stepper-horizontal-line,
  .mat-horizontal-stepper-header::before,
  .mat-horizontal-stepper-header::after {
    top: 45px;
    border-top-width: 2px !important;
    //background-color: var(--goa-color-interactive-default, #0070c4) !important;
  }

  //make the stepper connector lines attach to the icons (no gap)
  .mat-horizontal-stepper-header::before,
  .mat-horizontal-stepper-header::after {
    width: calc(50% - 20px) !important;
  }
}

.mat-stepper-vertical {
  //make the stepper connector lines thicker, and shift them down because we made the icons larger
  .mat-stepper-vertical-line::before {
    left: 6px;
    border-left-width: 2px;
    top: -5px;
    bottom: -5px;
    //background-color: var(--goa-color-interactive-default, #0070c4) !important;
    //border-left-color: var(--goa-color-interactive-default, #0070c4) !important;
  }

  div[role='tabpanel'] {
    height: 0px !important;
    visibility: hidden !important;
  }
}

.mat-step-sub-label-error {
  line-height: 14px;
}

//make the stepper icons larger
.mat-step-icon {
  font-weight: bold !important;
  width: 40px !important;
  height: 40px !important;
}

.mat-step-icon-state-edit {
  background-color: var(--goa-color-greyscale-100, #f1f1f1) !important;
}

.mat-step-icon-state-notVisited {
  width: 40px !important;
  height: 40px !important;
  -webkit-box-shadow: inset 0px 0px 0px 4px #666 !important;
  -moz-box-shadow: inset 0px 0px 0px 4px #666 !important;
  box-shadow: inset 0px 0px 0px 4px #666 !important;

  color: var(--goa-color-greyscale-black, #333) !important;
  font-size: 16px !important;
  background-color: var(--goa-color-greyscale-100, #f1f1f1) !important;
}

.mat-step-icon-selected,
.mat-step-icon-edit {
  background-color: var(--goa-color-greyscale-100, #f1f1f1) !important;
  color: var(--goa-color-interactive-default, #0070c4) !important;
  -webkit-box-shadow: inset 0px 0px 0px 4px var(--goa-color-interactive-default, #0070c4);
  -moz-box-shadow: inset 0px 0px 0px 4px var(--goa-color-interactive-default, #0070c4);
  box-shadow: inset 0px 0px 0px 4px var(--goa-color-interactive-default, #0070c4);
}

.mat-step-icon.mat-step-icon-state-edit.mat-step-icon-selected > div > mat-icon {
  font-size: 24px;
  height: 24px;
  width: 24px;
}

.mat-step-icon-not-visited {
  background-color: var(--goa-color-greyscale-100, #f1f1f1) !important;
  color: var(--goa-color-greyscale-white, #fff) !important;
  -webkit-box-shadow: inset 0px 0px 0px 4px var(--goa-color-interactive-default, #0070c4);
  -moz-box-shadow: inset 0px 0px 0px 4px var(--goa-color-interactive-default, #0070c4);
  box-shadow: inset 0px 0px 0px 4px var(--goa-color-interactive-default, #0070c4);
}

.mat-step-icon.mat-step-icon-state-done.mat-step-icon-selected {
  background-color: var(--goa-color-interactive-default, #0070c4) !important;
}

.mat-stepper-vertical {
  .mat-step-icon.mat-step-icon-state-done.mat-step-icon-selected {
    background-color: var(--goa-color-greyscale-100, #f1f1f1) !important;
  }
}

.mat-step-label.mat-step-label-error {
  color: initial !important;
}

div.mat-step-label.mat-step-label-active.mat-step-label-error > div.mat-step-sub-label-error.ng-star-inserted {
  color: #ec040b !important;
}

.goa-banner {
  h1 {
    padding-top: 24px !important;
    line-height: 44px !important;
  }
  .descriptor {
    font-size: 24px !important;
    padding: 0 !important;
    padding-bottom: 24px !important;
    line-height: 32px !important;
  }
}

.goa-notification.goa--important .content .message {
  color: var(--goa-color-text-default, #333);
  margin: 0.1rem 1rem;
}

ol.goa-ordered-list,
ul.goa-unordered-list {
  font: 400 1.5rem/2rem acumin-pro-semi-condensed, helvetica-neue, arial, sans-serif; /* --goa-typography-body-l */
}

ol.goa-ordered-list li {
  margin-bottom: 1.25rem; /* --goa-line-height-1 */
  padding: 0.5rem; /* --goa-space-xs */
}

ol.goa-ordered-list ol,
ol.goa-ordered-list ul,
ul.goa-unordered-list ol,
ul.goa-unordered-list ul {
  margin-left: 1.5rem; /* --goa-space-l */
  margin-top: 1.25rem;
}

ul.goa-unordered-list li {
  margin-bottom: 1.25rem;
}

ul.goa-unordered-list li::marker {
  color: #0081a2; /* --goa-color-brand-default */
}

li span {
  position: relative;
  left: 5px;
}

.mat-tooltip {
  max-width: 480px !important;
  font-size: 16px;
}
